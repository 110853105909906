import { render, staticRenderFns } from "./CBSR.vue?vue&type=template&id=7fd8157a&scoped=true&"
import script from "./CBSR.vue?vue&type=script&lang=ts&"
export * from "./CBSR.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fd8157a",
  null
  
)

export default component.exports